@font-face {
    font-family: SFProRegular;
    src: local("SFProRegular"),
    url("./fonts/SFProRegular.otf") format("opentype");
}

@font-face {
    font-family: SFProMedium;
    src: local("SFProMedium"),
    url("./fonts/SFProMedium.otf") format("opentype");
}

:root {
    --text: white;
    /* --background: #cbcbcbda; */
    --background: #101010;
    --background-color:#101010;
    --primary-blue: #007BFF;
    --light-blue: #444;
    --dark-blue: #0056b3;
    --green: #1eb83f;
    --light-blue: #0080ff;
    --light-grey: #08192a8e;
    --light-red: #ff4747;





}
body.admin-dashboard-mode {
    --background: #f3f3f3; /* Light background color for AdminDashboard */
    --text: black; /* Dark text color for AdminDashboard */
}

html * {
    -ms-overflow-style: none;
    scrollbar-width: none;
    transition: none;
}

html, body {
    background-color: var(--background) !important;

}



::-webkit-scrollbar {
    display: none;
}

body {
    font-family: SFProRegular, sans-serif;
    color: var(--text);
    background-color: var(--background);
    margin: 0;
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.wrapper {
    background: var(--background);
    margin: 0px;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.centered {
    position: relative;
    height: 100vh;
    align-items: center;
    justify-content: center;
    display: flex;
    
}
.centered-home {
    align-items: center;
    overflow: auto;
    justify-content: center;
    /* display: flex; */
    height: 100vh;
    /* overflow-y: hidden; */

    position: relative;
    
}


.container {
    text-align: center;
    background: #23252b;
    background: #1b1b20b6;

    margin: 0 auto;
    border-radius: 30px;
    padding: 20px;
    padding-bottom: 25px;
    box-sizing: border-box;
    width: 89%;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
    height: 495px;
}

.image {
    width: 47px;
    height: 47px;
    border-radius: 50px;
}

.title {
    font-family: SFProMedium;
    font-size: 30px;
    margin-bottom: 15px;
}
.title-payment {
    font-family: SFProMedium;
    font-size: 30px;
    margin-bottom: 15px;
    color: #000;
}

.bubble {
    background: rgba(0, 123, 255, 0.1);
    border-color: rgba(0, 123, 255, 0.5);

    /* background: rgba(155, 190, 231, 0.051);
    border-color: rgba(238, 238, 239, 0.5); */


    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
}
.bubble-2 {
    /* background: rgba(0, 123, 255, 0.1); */
    /* border-color: rgba(0, 123, 255, 0.5); */

    background: rgba(155, 190, 231, 0.051);
    border-color: rgba(238, 238, 239, 0.5);


    border-style: solid;
    border-width: 1px;
    border-radius: 10px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    width: 100%;
    text-align: left;
}

.subtitle {
    font-family: SFProMedium;
    font-size: 16px;
    margin-bottom: 5px;
}

.text {
    font-family: SFProRegular;
    font-size: 18px;
    line-height: 1.5;
    color: var(--text);
}

.text-bold {
    font-family: SFProMedium;
    font-size: 15px;
    color: rgba(255, 255, 255, 0.5);
}

.text-clickable {
    color: var(--primary-blue);
    font-family: SFProMedium;
}

.text-clickable:hover {
    cursor: pointer;
    text-decoration: underline;
}

.button, .button2 {
    font-family: SFProMedium;
    color: var(--background);
    font-size: 20px;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 50px;
    box-sizing: border-box;
    height: 55px;
    width: 100%;
    text-decoration: none !important;
    opacity: 1;
    border: 2px solid #0056b3;    
    /* background: var(--primary-blue); */
        background:#1616166a;

    /* animation: glowing 100s infinite; */
}
.button-payment {
    font-family: SFProMedium;
    color: white;
    font-size: 20px;
    padding: 10px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 50px;
    box-sizing: border-box;
    height: 55px;
    width: 100%;
    text-decoration: none !important;
    opacity: 1;
    border: 2px solid #0056b3;    
    /* background: var(--primary-blue); */
        background:#0056b3;

    /* animation: glowing 100s infinite; */
}
.back-button{
    font-family: SFProMedium;
    color: var(--background);
    font-size: 10px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 50px;
    box-sizing: border-box;
    height: 35px;
    width: 12%;
    text-decoration: none !important;
    opacity: 1;
    border: 2px solid #5e5e60;    
    /* background: var(--primary-blue); */
        /* background:#1616166a; */

    /* animation: glowing 100s infinite; */
}

.back-button {
    color: white;
    background: transparent;
    border: none;
    cursor: pointer;
    margin-top: 7px;
    
    transition: transform 0.2s, color 0.2s; /* Add transition for smooth hover effect */
  }
  
  .back-button-payment {
    color: rgb(65, 64, 64);
    background: transparent;
    border: none;
    cursor: pointer;
    margin-top: -10px;
    
    transition: transform 0.2s, color 0.2s; /* Add transition for smooth hover effect */
  }

  .back-button:hover {
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
    color: #434343; /* Change the color on hover */
  }


  
  
  .back-button-icon {
    width: 30px; /* Adjust the size of the icon */
    height: 30px; /* Adjust the size of the icon */
  }
  

.button:hover, .button2:hover{
    cursor: pointer;
    opacity: 0.9 !important;
    background:#007BFF;
}

.back-button-payment:hover{
    transform: scale(1.1); /* Slightly enlarge the icon on hover */
    color: #b0b0b0; /* Change the color on hover */
}
.button-payment:hover{
    cursor: pointer;
    opacity: 0.9 !important;
    background:#007BFF;
}

.button:active, .button2:active {
    transform: scale(0.99);
}

.button2 {
    background: var(--background);
    color: white;
}

.textarea {
    font-family: SFProRegular;
    font-size: 18px;
    background: var(--background);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.1);
    height: 110px;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    resize: none;
    color: var(--text);
    border: none; /* No border by default */
    outline: none;
}

  
  .textarea:focus {
    border: .5px solid rgba(255, 255, 255, 0.608); /* White border on focus */
  }
  
  .textarea-error {
    border: 1px solid #ff4444; /* Red border when there's an error */
  }


  /* Add this to your CSS file */

  .form {
    font-family: SFProRegular;
    font-size: 18px;
    background: var(--background);
    border-style: solid;
    border-width: 1px;
    border-color: rgba(255, 255, 255, 0.1);
    height: 55px;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    padding-left: 20px;
    padding-right: 20px;
    box-sizing: border-box;
    color: var(--text);
    border: none; /* No border by default */
    outline: none;
  }
  
  .form:focus {
    border: 1px solid rgba(255, 255, 255, 0.608); /* White border on focus */
  }
  
  .form-error {
    border: 2px solid #ff4444; /* Red border when there's an error */
  }
  
@keyframes glowing {
    0% { box-shadow: 0 0 2px var(--primary-blue); }
    50% { box-shadow: 0 0 10px var(--primary-blue); }
    100% { box-shadow: 0 0 2px var(--primary-blue); }
}

.container {
    width: 400px;
    height: 560px;
    padding-left: 30px;
    padding-right: 30px;
}


.container.page-3 {
    width: 400px;
    height: 690px;
    padding-left: 30px;
    padding-right: 30px;
    background: #ffffffec;
}
.container.page-2 {
    width: 400px;
    height: 430px;
    padding-left: 30px;
    padding-right: 30px;
}

.button{
    margin-top: 200px
}

@media only screen and (max-height: 550px) 
{
.centered-home {
    justify-content: flex-start;
   
    overflow-y: auto;

    position: relative;
    

    
}
.centered {
    position: relative;
    height: 100vh;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    margin-top: 0px;
    top: 100px;

    
}


.chat-container{
      position: relative;
      margin-top: 100px;
                top: 54%; /* Adjust this value to position the chat container */
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                flex-direction: column;
                align-items: center;
                z-index: 3;
                margin-bottom: -100px;
                
}
.glow-container {
    position: relative;
    top: 20%;
    display: flex;
  
}
/* header h1 {
    font-size: 1rem;
    margin-bottom: 1rem;
    font-weight: bold;
     } */

     .container.page-3 {
        margin-top: 200px;

    }
}
/* DESKTOP STYLES */

@media only screen and (min-width: 787px) 
{
	.container {
		width: 440px;
		padding-left: 30px;
		padding-right: 30px;
        height: 530px;
	}
    .container.page-3 {
        width: 440px;
        height: 690px;
		padding-left: 30px;
		padding-right: 30px;
        background: #ffffffec;

    }
    .container.page-2 {
        width: 440px;
        height: 430px;
		padding-left: 30px;
		padding-right: 30px;
    }

	.landingPage_image {
		width: 100%;
		height: 100%;
		border-radius: 50px;
	}

    /* POP UP */
    .popup-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
    }

    .popup {
        position: relative;
        width: 80%;
        max-width: 1000px;
        background: white;
        border-radius: 10px;
        overflow: hidden;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }

    .popup-image {
        width: 100%;
        display: block;
    }

    .popup-close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        font-size: 20px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
    }

    .popup-close-button:hover {
        background: rgba(0, 0, 0, 0.8);
    }

   
}